import React, { useState, useRef, useEffect } from 'react';
import Communication from '../assets/Gallery/Missoula-Pic-9.jpg';
import '../styles.css';
import { Container, Row, Col, Button, Form, Alert } from 'react-bootstrap';

function ContactUs() {
  const [selectedOption, setSelectedOption] = useState(null);
  const formRef = useRef(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const [isFeedbackFormSubmitted, setIsFeedbackFormSubmitted] = useState(false);
  const [isBusinessFormSubmitted, setIsBusinessFormSubmitted] = useState(false);

  const [businessFormData, setBusinessFormData] = useState({
    companyName: '',
    contactPerson: '',
    position: '',
    streetAddress: '',
    city: '',
    state: '',
    email: '',
    phoneNumber: '',
    enquiry: '',
  });

  const [feedbackFormData, setFeedbackFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    date: '',
    location: '',
    firstVisit: '',
    itemsFound: '',
    comments: '',
    serviceGreeted: '',
    serviceEfficient: '',
    merchandiseSelection: '',
    merchandiseCleanliness: '',
    merchandiseEaseOfShopping: '',
  });

  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleFeedbackChange = (e) => {
    const { name, value } = e.target;
    setFeedbackFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  
  const handleBusinessChange = (e) => {
    const { name, value } = e.target;
    setBusinessFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  
  const handleOptionChange = (option) => {
    setSelectedOption(option);
    formRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevents page reload

    console.log('Feedback form data:', feedbackFormData);
    console.log('Business form data:', businessFormData);

    // Validate form fields
    const isValid =
  selectedOption === 'Feedback'
    ? feedbackFormData.name.trim() !== '' &&
      feedbackFormData.email.trim() !== '' &&
      feedbackFormData.phoneNumber.trim() !== '' &&
      feedbackFormData.date.trim() !== '' &&
      feedbackFormData.location.trim() !== '' &&
      feedbackFormData.firstVisit.trim() !== '' &&
      feedbackFormData.itemsFound.trim() !== '' &&
      feedbackFormData.comments.trim() !== '' &&
      feedbackFormData.serviceGreeted.trim() !== '' &&
      feedbackFormData.serviceEfficient.trim() !== '' &&
      feedbackFormData.merchandiseSelection.trim() !== '' &&
      feedbackFormData.merchandiseCleanliness.trim() !== '' &&
      feedbackFormData.merchandiseEaseOfShopping.trim() !== ''
    : businessFormData.companyName.trim() !== '' &&
      businessFormData.contactPerson.trim() !== '' &&
      businessFormData.position.trim() !== '' &&
      businessFormData.streetAddress.trim() !== '' &&
      businessFormData.city.trim() !== '' &&
      businessFormData.state.trim() !== '' &&
      businessFormData.email.trim() !== '' &&
      businessFormData.phoneNumber.trim() !== '' &&
      businessFormData.enquiry.trim() !== '';


    if (!isValid) {
      setShowError(true);
      setShowSuccess(false);
      return;
    }

    setShowError(false);
    setShowSuccess(false);
    setIsFormSubmitted(false);
    setSuccessMessage('Form submitted successfully!');

    const endpoint =
      selectedOption === 'Feedback'
        ? 'https://back-end-2-bpbw.onrender.com/submit-feedback'
        : 'https://back-end-2-bpbw.onrender.com/submit-inquiry';

    const formData =
      selectedOption === 'Feedback' ? feedbackFormData : businessFormData;

    fetch(endpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to submit form');
        }
        return response.json();
      })
      .then((data) => {
        if (selectedOption === 'Feedback') {
          setIsFeedbackFormSubmitted(true);
          setIsBusinessFormSubmitted(false);
        } else {
          setIsBusinessFormSubmitted(true);
          setIsFeedbackFormSubmitted(false);
        }

        setShowSuccess(true);
        setIsFormSubmitted(true);

        // Reset form fields
        setFeedbackFormData({
          name: '',
          email: '',
          phoneNumber: '',
          date: '',
          location: '',
          firstVisit: '',
          itemsFound: '',
          comments: '',
          serviceGreeted: '',
          serviceEfficient: '',
          merchandiseSelection: '',
          merchandiseCleanliness: '',
          merchandiseEaseOfShopping: '',
        });

        setBusinessFormData({
          companyName: '',
          contactPerson: '',
          position: '',
          streetAddress: '',
          city: '',
          state: '',
          email: '',
          phoneNumber: '',
          enquiry: '',
        });

        console.log('Form submitted successfully:', data);
      })
      .catch((error) => {
        setShowError(true);
        console.error('Error submitting form:', error);
      });
  };
  return (
    <Container className="contact-container">
      <div>
        <Row className="mb-4">
          <Col>
            <div className="ContactUsImageContaineri">
              <img src={Communication} alt="Image" className="ContactUsImagei" />
              <div className="text-overlayi">
                <p style={{ fontSize: 70, color: 'var(--yellow)' }}>CONTACT US</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row className="mb-4 text-center">
        <Col>
          <h2 className="contact-heading">We'd Love to Hear from You</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <div className="enquiry-options">
          <Col md={4}>
            <div className="enquiry-option" onClick={() => handleOptionChange('Feedback')}>
              <h2>Customer Feedback</h2>
              <p>Your feedback helps us improve. Let us know your thoughts!</p>
              <Button variant="primary">Provide Feedback</Button>
            </div>
          </Col>
          <Col md={4}>
            <div className="enquiry-option" onClick={() => handleOptionChange('Business')}>
              <h2>Business Inquiry</h2>
              <p>Interested in partnering with us? We'd love to hear from you!</p>
              <Button variant="primary">Inquire About Business</Button>
            </div>
          </Col>
        </div>
      </Row>
      {selectedOption && (
        <Row className="mb-4">
          <Col ref={formRef}>
          <div className="enquiry-form">
  {showError && <Alert variant="danger">Please complete all required fields.</Alert>}
  
  {/* Success message for Feedback form */}
  {showSuccess && selectedOption === 'Feedback' && isFeedbackFormSubmitted && (
    <Alert variant="success">Feedback form submitted successfully!</Alert>
  )}
  
  {/* Success message for Business form */}
  {showSuccess && selectedOption === 'Business' && isBusinessFormSubmitted && (
    <Alert variant="success">Business inquiry form submitted successfully!</Alert>
  )}


  
              {selectedOption === 'Feedback' && (
                <>
                  <h2>Customer Feedback</h2>
                  <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="name">
                      <Form.Label>Name:</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={feedbackFormData.name}
                        onChange={handleFeedbackChange}
                        placeholder="Enter your Full name"
                      />
                    </Form.Group>
                    <Form.Group controlId="email">
  <Form.Label>Email:</Form.Label>
  <Form.Control
    type="email"
    name="email"
    value={feedbackFormData.email}
    onChange={handleFeedbackChange}
    placeholder="you@example.com"
  />
</Form.Group>

<Form.Group controlId="phoneNumber">
  <Form.Label>Phone Number:</Form.Label>
  <Form.Control
    type="text"
    name="phoneNumber"
    value={feedbackFormData.phoneNumber}
    onChange={handleFeedbackChange}
    placeholder="(555) 555-5555"
  />
</Form.Group>
                    <Form.Group controlId="date">
                      <Form.Label>Date of Visit:</Form.Label>
                      <Form.Control
                        type="date"
                        name="date"
                        value={feedbackFormData.date}
                        onChange={handleFeedbackChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="location">
                      <Form.Label>Location:</Form.Label>
                      <Form.Control
                        type="text"
                        name="location"
                        value={feedbackFormData.location}
                        onChange={handleFeedbackChange}
                      />
                      </Form.Group>
                       <Form.Group controlId="firstVisit">
                                                                <Form.Label>Was this your first visit to the store?</Form.Label>
                                                                <div className="inline-radio-group">
                                                                  <Form.Check 
                                                                    type="radio" 
                                                                    label="Yes" 
                                                                    name="firstVisit" 
                                                                    value="Yes"
                                                                    checked={feedbackFormData.firstVisit === 'Yes'}
                                                                    onChange={handleFeedbackChange}
                                                                    inline
                                                                  />
                                                                  <Form.Check 
                                                                    type="radio" 
                                                                    label="No" 
                                                                    name="firstVisit" 
                                                                    value="No"
                                                                    checked={feedbackFormData.firstVisit === 'No'}
                                                                    onChange={handleFeedbackChange} 
                                                                    inline
                                                                  />
                                                                </div>
                                                              </Form.Group>
                                          
                                                              <Form.Group controlId="itemsFound">
                                                                <Form.Label>Of the item(s) you were shopping for, how many were you able to find?</Form.Label>
                                                                <div className="inline-radio-group">
                                                                  <Form.Check 
                                                                    type="radio" 
                                                                    label="All" 
                                                                    name="itemsFound" 
                                                                    value="All" 
                                                                    onChange={handleFeedbackChange}
                                                                    checked={feedbackFormData.itemsFound === 'All'}
                                                                    inline
                                                                  />
                                                                  <Form.Check 
                                                                    type="radio" 
                                                                    label="Most" 
                                                                    name="itemsFound" 
                                                                    value="Most" 
                                                                    checked={feedbackFormData.itemsFound === 'Most'}
                                                                    onChange={handleFeedbackChange}
                                                                    inline
                                                                  />
                                                                  <Form.Check 
                                                                    type="radio" 
                                                                    label="Some" 
                                                                    name="itemsFound" 
                                                                    value="Some"
                                                                    checked={feedbackFormData.itemsFound === 'Some'} 
                                                                    onChange={handleFeedbackChange}
                                                                    inline
                                                                  />
                                                                  <Form.Check 
                                                                    type="radio" 
                                                                    label="None" 
                                                                    name="itemsFound" 
                                                                    value="None"
                                                                    checked={feedbackFormData.itemsFound === 'None'} 
                                                                    onChange={handleFeedbackChange}
                                                                    inline
                                                                  />
                                                                </div>
                                                              </Form.Group>
                                                              
                                                                   
                                                              <div className="form-group">
  <label htmlFor="feedbackType">Please choose the best answer for each of the following:</label>
  {!isMobile ? (
    <>
      {/* Layout for larger screens */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginLeft: "190px",
          marginBottom: "10px",
        }}
      >
        <label style={{ width: "110px", textAlign: "center" }}>Excellent</label>
        <label style={{ width: "110px", textAlign: "center" }}>Good</label>
        <label style={{ width: "110px", textAlign: "center", marginRight: "10px" }}>
          Fair
        </label>
        <label style={{ width: "110px", textAlign: "center", marginRight: "20px" }}>
          Poor
        </label>
      </div>

      {/* SERVICE Section */}
      <label style={{ marginTop: "15px", fontWeight: "bold" }}>SERVICE</label>
      <div
        style={{ display: "flex", alignItems: "center", marginLeft: "20px" }}
      >
        <span style={{ width: "150px" }}>Greeted/Thanked</span>
        <input type="radio" name="serviceGreeted" value="Excellent" style={{ marginLeft: "60px" }} onChange={handleFeedbackChange}
      checked={feedbackFormData["serviceGreeted"] === "Excellent"} />
        <input type="radio" name="serviceGreeted" value="Good" style={{ marginLeft: "78px" }} onChange={handleFeedbackChange}
      checked={feedbackFormData["serviceGreeted"] === "Good"} />
        <input type="radio" name="serviceGreeted" value="Fair" style={{ marginLeft: "72px" }} onChange={handleFeedbackChange}
      checked={feedbackFormData["serviceGreeted"] === "Fair"}/>
        <input type="radio" name="serviceGreeted" value="Poor" style={{ marginLeft: "70px" }} onChange={handleFeedbackChange}
      checked={feedbackFormData["serviceGreeted"] === "Poor"} />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "20px",
          marginTop: "10px",
        }}
      >
        <span style={{ width: "150px" }}>Efficient</span>
        <input type="radio" name="serviceEfficient" value="Excellent" style={{ marginLeft: "60px" }} onChange={handleFeedbackChange}
      checked={feedbackFormData["serviceEfficient"] === "Excellent"} />
        <input type="radio" name="serviceEfficient" value="Good" style={{ marginLeft: "78px" }} onChange={handleFeedbackChange}
      checked={feedbackFormData["serviceEfficient"] === "Good"} />
        <input type="radio" name="serviceEfficient" value="Fair" style={{ marginLeft: "72px" }}  onChange={handleFeedbackChange}
      checked={feedbackFormData["serviceEfficient"] === "Fair"}/>
        <input type="radio" name="serviceEfficient" value="Poor" style={{ marginLeft: "70px" }} onChange={handleFeedbackChange}
      checked={feedbackFormData["serviceEfficient"] === "Poor"}/>
      </div>
      {/* MERCHANDISE Section */}
      <label style={{ marginTop: "15px", fontWeight: "bold" }}>MERCHANDISE</label>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "20px",
          marginTop: "10px",
        }}
      >
        <span style={{ width: "150px" }}>Selection</span>
        <input type="radio" name="merchandiseSelection" value="Excellent" style={{ marginLeft: "60px" }} onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseSelection"] === "Excellent"} />
        <input type="radio" name="merchandiseSelection" value="Good" style={{ marginLeft: "78px" }} onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseSelection"] === "Good"}  />
        <input type="radio" name="merchandiseSelection" value="Fair" style={{ marginLeft: "72px" }} onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseSelection"] === "Fair"}/>
        <input type="radio" name="merchandiseSelection" value="Poor" style={{ marginLeft: "70px" }} onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseSelection"] === "Poor"} />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "20px",
          marginTop: "10px",
        }}
      >
        <span style={{ width: "150px" }}>Cleanliness</span>
        <input type="radio" name="merchandiseCleanliness" value="Excellent" style={{ marginLeft: "60px" }}  onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseCleanliness"] === "Excellent"} />
        <input type="radio" name="merchandiseCleanliness" value="Good" style={{ marginLeft: "78px" }} onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseCleanliness"] === "Good"}/>
        <input type="radio" name="merchandiseCleanliness" value="Fair" style={{ marginLeft: "72px" }} onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseCleanliness"] === "Fair"} />
        <input type="radio" name="merchandiseCleanliness" value="Poor" style={{ marginLeft: "70px" }} onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseCleanliness"] === "Poor"}/>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "20px",
          marginTop: "10px",
        }}
      >
        <span style={{ width: "150px" }}>Ease of Shopping</span>
        <input type="radio" name="merchandiseEaseOfShopping" value="Excellent" style={{ marginLeft: "60px" }} onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseEaseOfShopping"] === "Excellent"}/>
        <input type="radio" name="merchandiseEaseOfShopping" value="Good" style={{ marginLeft: "78px" }} onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseEaseOfShopping"] === "Good"} />
        <input type="radio" name="merchandiseEaseOfShopping" value="Fair" style={{ marginLeft: "72px" }} onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseEaseOfShopping"] === "Fair"} />
        <input type="radio" name="merchandiseEaseOfShopping" value="Poor" style={{ marginLeft: "70px" }} onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseEaseOfShopping"] === "Poor"} />
      </div>
    </>

) : (
    <>
      {/* Layout for smaller screens */}
      <label style={{ marginTop: "15px", fontWeight: "bold" }}>SERVICE</label>
      <div style={{ marginBottom: "15px" }}>
        <span style={{ display: "block", fontWeight: "bold", marginBottom: "10px" }}>
          Greeted/Thanked
        </span>
        <label style={{ display: "block", marginBottom: "5px" }}>
          <input type="radio" name="serviceGreeted" value="Excellent" onChange={handleFeedbackChange}
      checked={feedbackFormData["serviceGreeted"] === "Excellent"} /> Excellent
        </label>
        <label style={{ display: "block", marginBottom: "5px" }}>
          <input type="radio" name="serviceGreeted" value="Good" onChange={handleFeedbackChange}
      checked={feedbackFormData["serviceGreeted"] === "Good"}/> Good
        </label>
        <label style={{ display: "block", marginBottom: "5px" }}>
          <input type="radio" name="serviceGreeted" value="Fair" onChange={handleFeedbackChange}
      checked={feedbackFormData["serviceGreeted"] === "Fair"}/> Fair
        </label>
        <label style={{ display: "block", marginBottom: "5px" }}>
          <input type="radio" name="serviceGreeted" value="Poor" onChange={handleFeedbackChange}
      checked={feedbackFormData["serviceGreeted"] === "Poor"}/> Poor
        </label>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <span style={{ display: "block", fontWeight: "bold", marginBottom: "10px" }}>Efficient</span>
        <label style={{ display: "block", marginBottom: "5px" }}>
          <input type="radio" name="serviceEfficient" value="Excellent" onChange={handleFeedbackChange}
      checked={feedbackFormData["serviceEfficient"] === "Excellent"} /> Excellent
        </label>
        <label style={{ display: "block", marginBottom: "5px" }}>
          <input type="radio" name="serviceEfficient" value="Good" onChange={handleFeedbackChange}
      checked={feedbackFormData["serviceEfficient"] === "Good"} /> Good
        </label>
        <label style={{ display: "block", marginBottom: "5px" }}>
          <input type="radio" name="serviceEfficient" value="Fair" onChange={handleFeedbackChange}
      checked={feedbackFormData["serviceEfficient"] === "Fair"}/> Fair
        </label>
        <label style={{ display: "block", marginBottom: "5px" }}>
          <input type="radio" name="serviceEfficient" value="Poor" onChange={handleFeedbackChange}
      checked={feedbackFormData["serviceEfficient"] === "Poor"} /> Poor
        </label>
      </div>

      <label style={{ marginTop: "15px", fontWeight: "bold" }}>MERCHANDISE</label>
      <div style={{ marginBottom: "15px" }}>
        <span style={{ display: "block", fontWeight: "bold", marginBottom: "10px" }}>Selection</span>
        <label style={{ display: "block", marginBottom: "5px" }}>
          <input type="radio" name="merchandiseSelection" value="Excellent" onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseSelection"] === "Excellent"} /> Excellent
        </label>
        <label style={{ display: "block", marginBottom: "5px" }}>
          <input type="radio" name="merchandiseSelection" value="Good" onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseSelection"] === "Good"}/> Good
        </label>
        <label style={{ display: "block", marginBottom: "5px" }}>
          <input type="radio" name="merchandiseSelection" value="Fair" onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseSelection"] === "Fair"} /> Fair
        </label>
        <label style={{ display: "block", marginBottom: "5px" }}>
          <input type="radio" name="merchandiseSelection" value="Poor" onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseSelection"] === "Poor"} /> Poor
        </label>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <span style={{ display: "block", fontWeight: "bold", marginBottom: "10px" }}>Cleanliness</span>
        <label style={{ display: "block", marginBottom: "5px" }}>
          <input type="radio" name="merchandiseCleanliness" value="Excellent" onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseCleanliness"] === "Excellent"} /> Excellent
        </label>
        <label style={{ display: "block", marginBottom: "5px" }}>
          <input type="radio" name="merchandiseCleanliness" value="Good" onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseCleanliness"] === "Good"} /> Good
        </label>
        <label style={{ display: "block", marginBottom: "5px" }}>
          <input type="radio" name="merchandiseCleanliness" value="Fair" onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseCleanliness"] === "Fair"}/> Fair
        </label>
        <label style={{ display: "block", marginBottom: "5px" }}>
          <input type="radio" name="merchandiseCleanliness" value="Poor" onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseCleanliness"] === "Poor"}/> Poor
        </label>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <span style={{ display: "block", fontWeight: "bold", marginBottom: "10px" }}>Ease of Shopping</span>
        <label style={{ display: "block", marginBottom: "5px" }}>
          <input type="radio" name="merchandiseEaseOfShopping" value="Excellent" onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseEaseOfShopping"] === "Excellent"} /> Excellent
        </label>
        <label style={{ display: "block", marginBottom: "5px" }}>
          <input type="radio" name="merchandiseEaseOfShopping" value="Good" onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseEaseOfShopping"] === "Good"}/> Good
        </label>
        <label style={{ display: "block", marginBottom: "5px" }}>
          <input type="radio" name="merchandiseEaseOfShopping" value="Fair" onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseEaseOfShopping"] === "Fair"} /> Fair
        </label>
        <label style={{ display: "block", marginBottom: "5px" }}>
          <input type="radio" name="merchandiseEaseOfShopping" value="Poor" onChange={handleFeedbackChange}
      checked={feedbackFormData["merchandiseEaseOfShopping"] === "Poor"}/> Poor
        </label>
      </div>
    </>
  )}






                                          
                 
                                                              <Form.Group controlId="comments">
  <Form.Label>Additional Comments:</Form.Label>
  <Form.Control
    as="textarea"
    rows={3}
    name="comments"
    value={feedbackFormData.comments}
    onChange={handleFeedbackChange} // Adding the onChange handler
  />
</Form.Group>
<Button
        variant="primary"
        type="submit"
        style={{
          backgroundColor: isFeedbackFormSubmitted ? "#28a745" : "#007bff", // Green if submitted, blue otherwise
          borderColor: isFeedbackFormSubmitted ? "#28a745" : "#007bff",
          color: "#fff", // Ensure text remains readable
          cursor: isFeedbackFormSubmitted ? "default" : "pointer", // Disable hover effect when submitted
        }}
        disabled={isFeedbackFormSubmitted} // Disable button after submission
      >
        {isFeedbackFormSubmitted ? 'Form Submitted Successfully!' : 'Submit Feedback'}
      </Button>

                  </div>
             
                  </Form>
                </>
              )}

              {selectedOption === 'Business' && (
                <>
                  <h2>Business Inquiry</h2>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="companyName">
                      <Form.Label>Company Name:</Form.Label>
                      <Form.Control
                        type="text"
                        name="companyName"
                        value={businessFormData.companyName}
                        onChange={handleBusinessChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="contactPerson">
                      <Form.Label>Contact Person:</Form.Label>
                      <Form.Control
                        type="text"
                        name="contactPerson"
                        value={businessFormData.contactPerson}
                        onChange={handleBusinessChange}
                        placeholder="Enter your Full name"
                      />
                    </Form.Group>

                    {/* Add additional business inquiry form fields here */}
                    <Form.Group controlId="position">
  <Form.Label>Position:</Form.Label>
  <Form.Control
    type="text"
    name="position"
    value={businessFormData.position}
    onChange={handleBusinessChange}
    placeholder="Enter your job title"
  />
</Form.Group>

<Form.Group controlId="streetAddress">
  <Form.Label>Street Address:</Form.Label>
  <Form.Control
    type="text"
    name="streetAddress"
    value={businessFormData.streetAddress}
    onChange={handleBusinessChange}
    placeholder="Enter your street address"
  />
</Form.Group>

<Form.Group controlId="city">
  <Form.Label>City:</Form.Label>
  <Form.Control
    type="text"
    name="city"
    value={businessFormData.city}
    onChange={handleBusinessChange}
    placeholder="City"
  />
</Form.Group>

<Form.Group controlId="state">
  <Form.Label>State:</Form.Label>
  <Form.Control
    type="text"
    name="state"
    value={businessFormData.state}
    onChange={handleBusinessChange}
    placeholder="State/Province"
  />
</Form.Group>

<Form.Group controlId="email">
  <Form.Label>Email:</Form.Label>
  <Form.Control
    type="email"
    name="email"
    value={businessFormData.email}
    onChange={handleBusinessChange}
    placeholder="you@example.com"
  />
</Form.Group>

<Form.Group controlId="phoneNumber">
  <Form.Label>Phone Number:</Form.Label>
  <Form.Control
    type="text"
    name="phoneNumber"
    value={businessFormData.phoneNumber}
    onChange={handleBusinessChange}
    placeholder="(555) 555-5555"
  />
</Form.Group>

<Form.Group controlId="enquiry">
  <Form.Label>Inquiry Details:</Form.Label>
  <Form.Control
    as="textarea"
    rows={3}
    name="enquiry"
    value={businessFormData.enquiry}
    onChange={handleBusinessChange}
    placeholder="Briefly describe your inquiry"
  />
</Form.Group>
<Button
        variant="primary"
        type="submit"
        style={{
          backgroundColor: isBusinessFormSubmitted ? "#28a745" : "#007bff", // Green if submitted, blue otherwise
          borderColor: isBusinessFormSubmitted ? "#28a745" : "#007bff",
          color: "#fff", // Ensure text remains readable
          cursor: isBusinessFormSubmitted ? "default" : "pointer", // Disable hover effect when submitted
        }}
        disabled={isBusinessFormSubmitted} // Disable button after submission
      >
        {isBusinessFormSubmitted ? 'Form Submitted Successfully!' : 'Submit Inquiry'}
      </Button>

                    
                  </Form>
                </>
              )}
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default ContactUs;
